import React from "react"

import Layout from "../components/layout"
import SEO from "./../components/Seo"

// styled components
import { Container } from "./../styles/GlobalStyles"

const NotFoundPage = props => (
  <Layout>
    <SEO
      title="Branding, Storytelling and Digital Experiences"
      pathname={props.location.pathname}
    />
    <Container>
      <h1>Page not found</h1>
      <p>The page you are trying to access does not exist.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
